<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane
      v-for="item in tabOptions"
      :key="item.id"
      :label="item.name"
      :name="item.id"
    >
      <slot :name="item.id"></slot>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  data() {
    return {
      activeName: "cn",
    };
  },
  computed: {
    tabOptions() {
      let datas = this.$store.getters.localeNames;
      let options = [];
      for (const key in datas) {
        options.push({
          id: key,
          name: datas[key],
        });
      }
      return options;
    },
  },
  methods: {
    handleClick(e) {
      this.$emit("change", e);
    },
  },
};
</script>

<style></style>
